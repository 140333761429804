<template>
  <div class="page account-module bg-primary-900 min-h-footer w-full text-white">
    <div class="flex flex-nowrap w-page my-0 mx-auto">
      <div class="global-container bg-primary-800 min-h-footer w-full flex1-shrink-0 flex-grow-0 pt-4"
           v-if="module && !loading">
        <!-- start module header content -->
        <div class="module-header-content flex flex-col xl:flex-row w-full">
          <!-- left side -->
          <div class="flex-grow p-0 m-0 xl:pr-8 flex flex-col relative xl:w-2/3">
            <module-preview></module-preview>
            <div  class="module-current-part px-6 lg:px-12 py-2 mt-3 italic" >Mis à jour le {{formatDate(module.modified)}}</div>
            <div class="module-current-part px-6 lg:px-12 py-8" v-if="currentModule">
              <account-module-progress :splits="splits" :percent="percent" class="mb-8"></account-module-progress>
              <account-module-header-texts :has-next="hasNextModule" :has-prev="hasPreviousModule"
                                           @next="handleNextModule" @prev="handlePreviousModule"
                                           :title="currentModule.title">
                <p class="mb-4" v-html="currentModule.content"></p>
              </account-module-header-texts>
            </div>
            <div class="px-6 lg:px-12 mb-12">
              <div class="horizontal-separator h-1 w-full bg-primary-900"></div>
            </div>
            <div class="px-6 lg:px-12 mb-12">
              <module-faqs :module="module"></module-faqs>
              <module-faqs-comments :module-id="module.id"></module-faqs-comments>
              <module-news :module="module"></module-news>
              <module-plan :module="module"  class="mt-8"></module-plan>
              <module-skills :module="module" class="mt-8"></module-skills>
              <module-participants :module="module" title="Formateurs & intervenants" class="mt-8"></module-participants>
            </div>
            <div class="download-all mt-20 bg-primary-500 mb-24" v-if="module.downloadUrl">
              <img :src="mockup" class="kit-image"/>
              <div class="text-container">
                <div class="uppercase font-normal text-xl leading-5">Télécharger toutes les ressources du module</div>
                <div class="text-xs leading-4">*Or ressource vidéo & podcast</div>
                <a :href="module.downloadUrl" target="_blank"
                    class="cursor-pointer mx-auto md:ml-0 mt-2 block w-fit text-sm bg-primary-900 px-6 py-2 rounded-full transition hover:bg-white hover:text-primary-900 text-white">
                  Télécharger
                </a>
              </div>
            </div>
            <div class="px-6 lg:px-12 mb-12 overflow-hidden">
              <module-list
                  v-if="linkedModules.length > 0"
                  bg="bg-primary-900"
                  hour-bg="bg-primary-800"
                  :modules="linkedModules"
                  title="Modules associés"
                  title-class="text-white text-2xl leading-6 font-medium"></module-list>
            </div>
            <module-comment :module-id="module.id" class="px-6 lg:px-12 mb-12"></module-comment>
          </div>
          <!-- end of left side -->
          <!-- right side -->
          <div class="side-menu-current z-20 flex px-6 py-4 relative" v-if="currentResource" @click="openSideMenu">
            <div class="group-title border-l-6 pl-2 cursor-pointer" :class="`border-${currentResource.color}`">
              <div class="uppercase font-medium leading-5">{{ currentResource.title }}</div>
              <div class="text-sm leading-4 opacity-70" v-if="currentModule">{{ currentModule.title }}</div>
              <div class="right-logo z-10">
                <img :src="currentResource.icon" width="54" height="54" class="scaleIn dur-3" alt="Logo section"/>
              </div>
            </div>
          </div>
          <div class="side-menu-backdrop z-20" :class="{'menu-open': sideMenuOpenState}" @click="closeSideMenu"></div>
          <div class="side-menu-close z-20" :class="{'menu-open': sideMenuOpenState}" @click="closeSideMenu">x</div>
          <div :class="{'menu-open': sideMenuOpenState}"
              class="side-menu scroll-primary-500 with-scroll bg-primary-800 pl-2 pr-4 pt-6 xl:bg-none  xl:p-0  flex-shrink-0 ml-auto mr-0 z-20 xl:mt-5">
            <account-module-tree @click-menu="handleClickOnMenu"></account-module-tree>
          </div>
          <!-- end of right side -->
        </div>
        <!-- end module header content -->
      </div>
      <div v-if="loading || !module" class="p-8 mx-auto flex-grow">
        <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                                  repeatCount="indefinite"/>
              </path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import * as ImageHelper from '@/_helpers/image.helper';
import { resolve } from '../../_helpers/image.helper';
import dateMixin from '@/mixins/dateMixin';

const mockup = resolve('mockup/Mockup-KitCompletRessources.png');

@Component({
  name: 'AccountModuleDetail',
  components: {
    ModulePreview: () => import(/* webpackPrefetch: true */ './account-module/module-preview.vue'),
    // ModuleComment: () => import(/* webpackPrefetch: true */ './account-module/account-module-comment.vue'),
    // ModuleFaqsComments: () => import(/* webpackPrefetch: true */ './account-module/account-module-faqs-question.vue'),
    PlyrPlayer: () => import(/* webpackPrefetch: true */ '@/components/player/plyr-player.vue'),
    ModuleNews: () => import(/* webpackPrefetch: true */ '@/components/module-details/module-news.vue'),
    ModuleFaqs: () => import(/* webpackPrefetch: true */ '@/components/module-details/module-faqs.vue'),
    ModuleList: () => import(/* webpackPrefetch: true */ '@/components/module-list/module-list.vue'),
    ModulePlan: () => import(/* webpackPrefetch: true */ '@/components/module-details/module-plan.vue'),
    ModuleSkills: () => import(/* webpackPrefetch: true */ '@/components/module-details/module-skills.vue'),
    ModuleParticipants: () => import(/* webpackPrefetch: true */ '@/components/module-details/module-participants.vue'),
    AccountModuleHeaderTexts: () => import(/* webpackPrefetch: true */ '@/views/pages/account-module/account-module-header-texts.vue'),
    AccountModuleProgress: () => import(/* webpackPrefetch: true */ '@/views/pages/account-module/account-module-progress.vue'),
    AccountModuleTree: () => import(/* webpackPrefetch: true */ '@/views/pages/account-module/account-module-tree.vue'),
  },
  data() {
    return {
      mockup,
      splits: [
        {
          color: 'bg-tip-corail',
          from: 0,
          to: 20,
        },
        {
          color: 'bg-tip-jaune',
          from: 20,
          to: 40,
        },
        {
          color: 'bg-tip-orange',
          from: 40,
          to: 60,
        },
        {
          color: 'bg-tip-bleu',
          from: 60,
          to: 80,
        },
        {
          color: 'bg-tip-vert',
          from: 80,
          to: 100,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      loading: (s) => s.auth.module.loading,
      module: (s) => s.auth.module.module,
      resources: (s) => s.auth.module.resources,
      currentModule: (s) => s.auth.module.currentResource,
      sideMenuOpenState: (s) => s.auth.module.sideMenuopenState,
    }),
    currentResource() {
      if (!this.currentModule) return null;
      const resources = this.$store.state.auth.module.resources.filter((r) => (
        r.children.filter((c) => c.id === this.currentModule.id).length > 0
      ));
      if (resources.length === 0) return null;
      return resources[0];
    },
    percent() {
      return this.$store.getters['auth/module/percent'];
    },
    linkedModules() {
      return this.module ? this.module.moduleLinks.map((link) => link.module) : [];
    },
    modulesList() {
      return [].concat([], ...this.resources.map((resource) => (resource.children)));
    },
    hasNextModule() {
      if (!this.currentModule || !this.resources) return false;
      if (this.modulesList.length === 0) return false;
      return this.modulesList.map((m) => m.id).indexOf(this.currentModule.id) < this.modulesList.length - 1;
    },
    hasPreviousModule() {
      if (!this.currentModule || !this.resources) return false;
      if (this.modulesList.length === 0) return false;
      return this.modulesList[0].id !== this.currentModule.id;
    },
  },
  mixins: [dateMixin],
  methods: {
    closeSideMenu() {
      this.$store.commit('auth/module/SET_SIDE_MENU_OPEN_STATE', false);
    },
    openSideMenu() {
      this.$store.commit('auth/module/SET_SIDE_MENU_OPEN_STATE', true);
    },
    resolve(imageUrl) {
      return ImageHelper.resolve(imageUrl);
    },
    handlePreviousModule() {
      this.$store.commit('auth/module/PREV_RESOURCE');
    },
    handleNextModule() {
      this.$store.commit('auth/module/NEXT_RESOURCE');
    },
    handleClickOnMenu(menu) {
      return menu;
    },
  },
  async mounted() {
    await this.$store.dispatch('auth/module/fetch', this.$route.params.id);
  },
})
export default class AccountModuleDetail extends Vue {
}
</script>

<style scoped>

</style>
